import { useRef, useEffect } from 'react';
import '/assets/styles/sections/header/partials/account-menu-desktop.scss';

// Partials
import Btn from '/views/partials/btn'
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Icon from '@wearetla/tla-essentials-tools/partials/icon';

// Context
import { useHeader } from '..';
import { useAuth } from '@wearetla/tla-essentials-tools/utilities/auth';
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals';

// Hooks
import useAccountMenuItems from '/hooks/account-menu-items';
import classNames from 'classnames';

export const DesktopAccountMenu = () => {
	const container = useRef();
	
	const { userData, initialized } = useAuth();
	const { openModal } = useModals();
	const { showAccountMenu: show, setShowAccountMenu: setShow } = useHeader();
	const menuItems = useAccountMenuItems();

	useEffect(() => {
		const bodyClickEvent = (e) => {
			if(container.current && !container.current.contains(e.target)) {
				setShow(false);
			}
		}

		window.document.body.addEventListener('click', bodyClickEvent, false);

		return () => {
			window.document.body.removeEventListener('click', bodyClickEvent, false);
		}
	}, [])

	return (
		<div className="header-account" ref={container}>
			{userData ?
				<Btn
					onClick={() => {
						setShow(true);
					}}
					afterIcon="angle-down"
					wrapText
					className="account-userbtn white outline block">
					{userData.name}
				</Btn>
				:
				<Btn
					disabled={!initialized}
					loading={!initialized}
					onClick={() => {
						openModal('auth');
					}}
					className="account-loginbtn white outline block">
					Giriş Yap
				</Btn>
			}

			{(!!userData && show) &&
				<div className="account-usermenu">
					<strong className="usermenu-greeting">Merhaba {userData.name}</strong>

					<div className="usermenu-nav">
						{menuItems.map(({ tag, title, icon, className, ...props }, nth) => {
							const ElemTag = (!tag || tag === 'link') ? Link : tag;

							return (
								<ElemTag
									className={classNames('nav-link', className)}
									key={nth}
									{...props}>
									<Icon name={icon} className="link-icon" /> {title}	
								</ElemTag>
							)
						})}
					</div>
				</div>
			}
		</div>
	);
}

export default DesktopAccountMenu;